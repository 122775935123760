<template>
  <section class="Library">
    <!-- 页面 header 通屏大图 -->
    <FullBanner :bannerURL="$route.meta.img" moduleName="Library">
      <template v-slot:customFill>
        <iq-card class="iq-card">
          <section
            class="container d-flex align-items-center"
            style="height: 90px"
          >
            <el-row style="width: 100%">
              <el-col :span="24">
                <el-input
                  clearable
                  @clear="getLibrary('', '')"
                  @keyup.enter.native="getLibrary('', '',keyword)"
                  class="w-100"
                  placeholder="Search"
                  v-model="keyword"
                >
                <i
                  slot="suffix"
                  class="el-input__icon el-icon-search cursor-pointer"
                  @click="getLibrary('', '',keyword)"
                ></i>
                </el-input>
              </el-col>
            </el-row>
          </section>
        </iq-card>
      </template>
    </FullBanner>
    <div v-if="loading" class="container library_container">
      <el-skeleton class="skeletonStyle" animated>
        <template slot="template">
          <el-skeleton-item variant="text" class="item1"  />
          <div style="padding: 10px 5px;" class="d-flex flex-wrap">
            <el-skeleton-item variant="text" class="item2" v-for="i of 8" :key="i" />
          </div>
          <el-skeleton-item variant="text" class="item3" />
          <div style="padding: 10px 5px;" class="d-flex flex-wrap">
            <div class="iq-card item3_5" v-for="i of 8" :key="i" >
              <el-skeleton-item class="item4" variant="image" />
              <el-skeleton-item class="item5" variant="text" />
            </div>
          </div>
        </template>
      </el-skeleton>
    </div>
    <div v-else class="container library_container">
      <ul class="bread_crumb">
        <li @click="getLibrary(0, 0)">Folders</li>
        <li
          @click="getLibrary(item.class_id, index + 1)"
          v-for="(item, index) in breadList"
          :key="item.class_id"
        >
          {{ item.name }}
        </li>
      </ul>
      <div v-if="tableData1.length || tableData2.length">
        <div v-show="tableData1 && tableData1.length" class="section Folders">
          <!-- <h3>Folders</h3> -->
          <!-- <ul class="bread_crumb">
            <li @click="getLibrary(0, 0)">Folders</li>
            <li
              @click="getLibrary(item.class_id, index + 1)"
              v-for="(item, index) in breadList"
              :key="item.class_id"
            >
              {{ item.name }}
            </li>
          </ul> -->
          <ul class="folder-list p-0">
            <li
              @click="getLibrary(item.id)"
              v-for="item in tableData1"
              :key="item.id"
            >
              <div class="img_box">
                <img src="./img/fold.png" alt="" />
              </div>
              <p class="m-0">{{ item.name }}</p>
            </li>
          </ul>
        </div>
        <div v-show="tableData2 && tableData2.length" class="section Files">
          <h3>Files</h3>
          <ul class="file-list p-0">
            <li
              @click="toLink(item.link)"
              v-for="item in tableData2"
              :key="item.id"
            >
              <div class="img_h_box">
                <div class="file_img_box">
                  <i v-show="item.type == 1" class="ri-file-text-line"></i>
                  <i v-show="item.type == 2" class="ri-image-line"></i>
                  <i v-show="item.type == 3" class="ri-vidicon-line"></i>
                  <i v-show="item.type == 4" class="ri-music-2-line"></i>
                </div>
              </div>
              <p class="mt-2 ml-3 file_p">{{ item.name }}</p>
            </li>
          </ul>
        </div>
      </div>
      <b-row
        v-else
        class="d-flex justify-content-center"
        style="background:#fff;padding-top: 60px; padding-bottom: 60px"
      >
        <img style="width:250px;" src="../../assets/images/group/empty1.png" alt="" />
      </b-row>
    </div>
  </section>
</template>
<script>
import FullBanner from "@/components/ComComponents/FullBanner";

export default {
  components: {
    FullBanner,
  },
  data() {
    return {
      keyword: "",
      tableData1: [],
      tableData2: [],
      parent: "",
      parent_id: 0,
      isDet: false,
      loading: true,

      breadList: [
        // {
        //   name: "Library",
        //   class_id: 0,
        // },
      ],
    };
  },
  created() {
    this.getLibrary();
  },

  methods: {
    getLibrary(id, index,keyword="") {
      this.loading = true
      this.$http
        .getLibrary({
          class_id: id ? id : 0,
          keyword,
        })
        .then((res) => {
          this.tableData1 = res.data.class;
          this.tableData2 = res.data.library;

          // 点击中间选项，清空后面
          if (index || index == 0) {
            this.breadList.forEach((clearItem, i) => {
              if (i >= index) this.breadList.pop(this.breadList[i]);
            });
          }
          // if (res.data && res.data.parent_name && res.data.parent_id) {
          //   let filterList = this.breadList.filter(item => item.id == res.data.parent_id);
          //   if (!filterList.length) this.breadList.push({
          //     name: res.data.parent_name,
          //     id: res.data.parent_id
          //   })
          // }
          this.breadList = res.data.level;
          setTimeout(() => {
            this.loading = false
          }, 0)
        });
    },
    toLink(link) {
      if (link.includes("http")) {
        window.open(link, "_blank");
      } else {
        window.open(`https://${link}`, "_blank");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-input__inner {
  height: 45px;
  border-radius: 11px;
}
::v-deep .el-breadcrumb__inner {
  font-size: 20px;
}
@media (min-width: 600px){
  .skeletonStyle{
    .item1{
      width: 200px;
      height:35px;
      margin-left:15px
    }
    .item2{
      width: 30%;
      height:65px; 
      margin: 10px;
    }
    .item3{
      width: 200px;
      height:35px;
      margin-top: 20px;
      margin-left:15px
    }
    .item3_5{
      width:30%; 
      margin: 10px;
    }
    .item4{
      height: 120px;
    }
    .item5{
      width:120px;
      margin-top:15px;
    }
  }
  .section {
    > ul {
      li {
        width: calc(33.3333% - 15px);
      }
    }
  }
}
@media (max-width: 600px){
.skeletonStyle{
  .item1{
    width: 200px;
    height:35px;
    margin-left:15px
  }
  .item2{
    width: 44%;
    height:65px; 
    margin: 10px;
  }
  .item3{
    width: 200px;
    height:35px;
    margin-top: 20px;
    margin-left:15px
  }
  .item3_5{
    width:44%; 
    margin: 10px;
  }
  .item4{
      height: 120px;
    }
    .item5{
      width:120px;
      margin-top:15px;
    }
}
  .section {
    > ul {
      li {
        width: calc(50% - 15px);
        p{
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
.Library {
  min-height: 100vh;

  .library_container {
    background: #fff;
    padding-top: 20px;
    .bread_crumb {
      display: flex;
      padding-left: 0;
      align-items: center;
      // padding-bottom: 20px;
      > li {
        position: relative;
        margin-right: 34px;
        font-size: 14px;
        color: #50b5ff;
        &:hover {
          color: #56b8ff;
          text-decoration: underline;
          cursor: pointer;
        }
        &:last-child {
          color: #333;
          cursor: default;
          text-decoration: none;
          &::before {
            display: none;
          }
        }
        &:first-child {
          font-size: 20px;
          &::before {
            top: 5.8px;
          }
        }
        &::before {
          content: ">>";
          display: block;
          position: absolute;
          right: -26px;
          color: #333;
          font-size: 14px;
        }
      }
    }
    .section {
      > h3 {
        font-size: 20px;
        color: #333333;
        margin-bottom: 20px;
      }
      > ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        li {
          padding: 20px;
          box-shadow: 0px 0px 20px 0px rgba(43, 101, 143, 0.1);
          border-radius: 5px;
          box-sizing: border-box;
          // width: calc(33.3333% - 15px);
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          margin-right: 15px;

          p {
            &:hover {
              color: #56b8ff;
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }
    }
    .Folders {
      .img_box {
        width: 23px;
        height: 20px;
        margin-right: 20px;
        > img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      > p {
        color: #333;
      }
    }

    div.Files {
      ul.file-list {
        > li {
          flex-direction: column;
          align-items: flex-start;
          height: 170px;
          padding: 0;
          > .img_h_box {
            width: 100%;
            height: 120px;
            background: #fafafb;
            > .file_img_box {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              > i {
                font-size: 32px;
                color: #ccc;
              }
            }
          }
        }
      }
    }
  }
}
</style>